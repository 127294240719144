export default class Toggler {
  constructor (deps) {
    this.$http = deps.toggler
    this.vertical = deps.vertical
  }

  async getCastKeys () {
    const resp = await this.$http.get(`config/vertical/${this.vertical}/cast`)
    return resp.data
  }

}
